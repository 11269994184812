<template>
  <div class="content">
    <div class="cell">
      <div class="header">
        <div>
          {{ title }}
          <span style="color: #4b7be5">
            ({{ list.length || dataList.data.length }})
          </span>
        </div>
        <template v-if="dataList.examine_status">
          <div
            class="tips"
            v-if="dataList.examine_status == 3"
            style="color: red"
          >
            驳回原因:{{ dataList.reject_reason }}
          </div>
          <div
            class="tips"
            v-else-if="dataList.examine_status == 2"
            style="color: #13ce66"
          >
            审核通过
          </div>
          <div class="tips" v-else style="color: #000">未审核</div>
        </template>
      </div>
      <div class="item">
        <template v-if="dataList.examine_status">
          <div
            class="tips"
            v-if="dataList.examine_status == 3"
            style="color: red"
          >
            <span style="margin-right: 10px" v-if="dataList.examine_at">
              上次审核:{{
                JSON.parse(dataList.last_examine_snap)
                  ? JSON.parse(dataList.last_examine_snap).examine_status == 2
                    ? "通过"
                    : JSON.parse(dataList.last_examine_snap).examine_status == 3
                    ? "驳回"
                    : "未审核"
                  : ""
              }}&nbsp;&nbsp;&nbsp;{{ dataList.examine_at }}
            </span>
            <!-- 驳回原因:{{ dataList.reject_reason }} -->
            <div
              style="margin-top: 5px"
              v-if="
                JSON.parse(dataList.last_examine_snap) &&
                JSON.parse(dataList.last_examine_snap).examine_status == 3
              "
            >
              驳回原因:{{
                JSON.parse(dataList.last_examine_snap).reject_reason
              }}
            </div>
            <el-button
              class="filter-item"
              type="primary"
              @click="onekeyDowload(dataList.data)"
            >
              一键下载
            </el-button>
          </div>
          <div
            class="tips"
            v-else-if="dataList.examine_status == 2"
            style="color: #13ce66"
          >
            <span style="margin-right: 10px" v-if="dataList.examine_at">
              上次审核:{{
                JSON.parse(dataList.last_examine_snap)
                  ? JSON.parse(dataList.last_examine_snap).examine_status == 2
                    ? "通过"
                    : JSON.parse(dataList.last_examine_snap).examine_status == 3
                    ? "驳回"
                    : "未审核"
                  : ""
              }}&nbsp;&nbsp;&nbsp;{{ dataList.examine_at }}
            </span>
            <!-- 审核通过 -->
            <div
              style="margin-top: 5px"
              v-if="
                JSON.parse(dataList.last_examine_snap) &&
                JSON.parse(dataList.last_examine_snap).examine_status == 3
              "
            >
              驳回原因:{{
                JSON.parse(dataList.last_examine_snap).reject_reason
              }}
            </div>
            <el-button
              class="filter-item"
              type="primary"
              @click="onekeyDowload(dataList.data)"
            >
              一键下载
            </el-button>
          </div>
          <div class="tips" v-else style="color: #000">
            <span style="margin-right: 10px" v-if="dataList.examine_at">
              上次审核:{{
                JSON.parse(dataList.last_examine_snap)
                  ? JSON.parse(dataList.last_examine_snap).examine_status == 2
                    ? "通过"
                    : JSON.parse(dataList.last_examine_snap).examine_status == 3
                    ? "驳回"
                    : "未审核"
                  : ""
              }}&nbsp;&nbsp;&nbsp;{{ dataList.examine_at }}
            </span>
            <!-- 未审核 -->
            <div
              style="margin-top: 5px"
              v-if="
                JSON.parse(dataList.last_examine_snap) &&
                JSON.parse(dataList.last_examine_snap).examine_status == 3
              "
            >
              驳回原因:{{
                JSON.parse(dataList.last_examine_snap).reject_reason
              }}
            </div>
            <el-button
              class="filter-item"
              type="primary"
              @click="onekeyDowload(dataList.data)"
            >
              一键下载
            </el-button>
          </div>
        </template>
      </div>
      <div class="item" v-for="(item, key) in dataList.data || list" :key="key">
        <div class="name">
          {{ key + 1 }}.文件名: {{ item.name }}
          <div style="margin-top: 10px">
            <span v-if="item.upload_at"> 上传时间:{{ item.upload_at }} </span>
          </div>
        </div>
        <div class="btn" style="min-width: 188px">
          <!-- <span style="margin-right: 10px" v-if="item.upload_at">
            上传时间:{{ item.upload_at }}
          </span> -->
          <el-button
            v-if="item.name && isAssetTypeAnImage(item.url)"
            size="mini"
            type="success"
            @click="preview(item)"
          >
            预览
          </el-button>
          <el-button
            v-if="item.name"
            size="mini"
            type="primary"
            @click="download(item)"
          >
            下载
          </el-button>
          <!-- v-if="status != 2"-->
          <template>
            <el-upload
              v-if="!item.name"
              class="upload-demo"
              :action="qiniu.actionPath"
              :data="qiniu.postData"
              multiple
              accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx.mp3,.pdf,.zip,.rar,.png,.jpg,.jpeg"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <!-- :on-exceed="uploadExceed" -->
              <!--  :limit="5"-->
              <el-button size="mini" type="warning" @click="index = key">
                上传
              </el-button>
            </el-upload>
            <el-button s size="mini" type="danger" @click="del(key)">
              删除
            </el-button>
          </template>
        </div>
      </div>

      <el-upload
        ref="upload"
        class="upload-demo"
        style="width: 100px; margin: 0 auto"
        :action="qiniu.actionPath"
        :data="qiniu.postData"
        multiple
        accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.pdf,.zip,.rar,.png,.jpg,.jpeg"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :before-upload="beforeUpload"
        :on-progress="beforeAvatarUpload"
        :show-file-list="false"
      >
        <!-- :on-exceed="uploadExceed" -->
        <!-- :limit="5" v-if="list.length < 5"-->
        <el-button size="mini" style="margin-top: 10px"> 添加 </el-button>
      </el-upload>

      <el-divider></el-divider>
      <div class="bottom_row">
        <!-- <template v-if="dataList.examine_status == 1">
          <el-button size="mini" type="danger" @click="reject(dataList.id)"
            >驳回</el-button
          >
          <el-button size="mini" type="success" @click="pass(dataList.id)"
            >通过</el-button
          >
        </template> -->
        <!-- <el-button
          v-if="
            (dataList.data.length > 0 || list.length > 0) &&
            (dataList.examine_status == 2 || dataList.examine_status == 3)
          "
          size="mini"
          type="info"
          @click="cashier(dataList.id)"
          :loading="cashierLoading"
        >
          撤回审核
        </el-button> -->
      </div>

      <el-dialog
        title="上传中"
        :visible.sync="dialogVisible"
        width="30%"
        :show-close="false"
        :center="true"
        :close-on-click-modal="false"
      >
        <div class="progresBox" v-for="(item, i) in progresList" :key="i">
          <p>{{ item.name }}</p>
          <el-progress
            :percentage="item.progress"
            style="width: 100%"
          ></el-progress>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import moment from "moment";
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";
import JSZip, { forEach } from "jszip";
import axios from "axios";
import fileSaver from "file-saver";
const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};
export default {
  name: "Batch_add",
  props: {
    dataList: {
      type: Object,
      default: {
        data: [],
      },
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    isbtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      cashierLoading: false,
      dialogVisible: false,
      fullscreenLoading: false,
      index: "",
      qiniu: {
        actionPath: "",
        baseUrl: "",
        postData: {},
      },
      uplist: [],
      list: [],
      progresList: [],
      loadProgress: 0,
      classdata: null,
    };
  },
  created() {
    getQiniuToken().then((response) => {
      console.log(response);
      // token可多次使用
      this.qiniu.actionPath = response.data.uploadUrl;
      this.qiniu.baseUrl = response.data.baseUrl;
      // console.log(response)
      if (!this.qiniu.postData.token) {
        this.qiniu.postData.token = response.data.token;
      }
    });
  },
  mounted() {
    this.list = this.dataList.data;
    this.classdata = JSON.parse(localStorage.getItem("classdata"));
    console.log(this.classdata);
    console.log(this.list, "====================渲染");
  },
  methods: {
    onekeyDowload(list) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const data = [];
      list.forEach((item) => {
        console.log(item, "==============");
        if (item.url) {
          let routeUrl = item.url.replace(/http:/, "https:");
          data.push({ url: routeUrl, name: item.name });
        }
      });
      // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      data.forEach((item) => {
        console.log(item);
        const promise = getFile(item.url).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象
          // const arr_name = item.split("/");
          const file_name = item.name; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          loading.close();
          // 生成二进制流
          fileSaver.saveAs(
            content,
            `${this.classdata.college_name}${this.classdata.school_year}-${
              this.classdata.school_year - 0 + 1
            }年第${this.classdata.semester == 1 ? "一" : "二"}学期${
              this.classdata.classes_name
            }--${this.text}.zip`
          ); // 利用file-saver保存文件
        });
      });
    },
    beforeUpload(file) {
      this.progresList = [];
      this.$forceUpdate(); //强制更新
      this.dialogVisible = true;
      // const isLt2M = file.size / 1024 / 1024 < 20;
      // if (!isLt2M) {
      //   this.$message({
      //     message: "上传文件大小不能超过 20MB!",
      //     type: "warning",
      //   });
      //   return false;
      // }
      this.qiniu.postData.key =
        guid() + "." + file.name.split(".")[file.name.split(".").length - 1];
    },
    //上传进度条
    beforeAvatarUpload(event, file, fileList) {
      // console.log(event, file, fileList);
      // console.log('进度条列表:',fileList);
      if (fileList.length > 0) {
        fileList.forEach((element, index) => {
          if (element.uid === file.uid) {
            const progress = Math.floor(event.percent);
            element.progress = progress;
            this.$set(fileList, index, element);
          }
        });
        this.progresList = fileList;
        if (this.progresList.every((item) => item.progress === 100)) {
          setTimeout(() => {
            this.dialogVisible = false;
          }, 2000);
        }
      }
    },
    //文件类型判断
    isAssetTypeAnImage(ext) {
      var index = ext.lastIndexOf(".");
      var ext = ext.substr(index + 1);
      return (
        ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf","png", "jpg", "jpeg",].indexOf(
          ext.toLowerCase()
        ) !== -1
      );
    },
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.crossOrigin = "Anonymous"; //允许跨域
        img.src = url;
        img.onload = function () {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL("image/png");
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    judgeType(name, type) {
      let result = "";
      let suffix = "";
      let imglist = [];
      try {
        var flieArr = name.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      if (type == 1) {
        imglist = ["png", "jpg", "jpeg", "bmp", "gif", "pdf"];
      } else {
        imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      }
      result = imglist.some(function (item) {
        return item == suffix;
      });
      return result;
    },
    preview(item) {
      let res = this.judgeType(item.url, 1);
      if (res) {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(routeUrl, "_blank");
      } else {
        let routeUrl = item.url;
        let officeUrl =
          "https://view.officeapps.live.com/op/view.aspx?src=" + routeUrl;
        window.open(officeUrl, "_blank");
      }
    },
    download(item) {
      console.log(item);
      let res = this.judgeType(item.url, 2);
      if (res) {
        this.getUrlBase64(item.url).then((base64) => {
          let link = document.createElement("a");
          link.href = base64;
          link.download = `${this.classdata.college_name}${
            this.classdata.school_year
          }-${this.classdata.school_year - 0 + 1}年第${
            this.classdata.semester == 1 ? "一" : "二"
          }学期${this.classdata.classes_name}——${item.name}`;
          link.click();
        });
      } else {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(
          routeUrl +
            "?attname=" +
            `${this.classdata.college_name}${this.classdata.school_year}-${
              this.classdata.school_year - 0 + 1
            }年第${this.classdata.semester == 1 ? "一" : "二"}学期${
              this.classdata.classes_name
            }——${item.name}`,
          "_parent"
        );
      }
    },
    cashier(id) {
      this.cashierLoading = true;
      request({
        url: "/api/backend/eduAdmin/dataRevokeExamine",
        method: "post",
        data: { id },
      }).then((response) => {
        this.cashierLoading = false;
        this.$message({
          type: "success",
          message: "撤回成功",
        });
        // this.$router.go(0);
        this.$emit("update");
      });
    },
    reject(id) {
      this.$prompt("请输入驳回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          request({
            url: "/api/backend/eduAdmin/dataReject",
            method: "post",
            data: { id, reject_reason: value },
          }).then((response) => {
            this.$message({
              type: "success",
              message: "驳回成功",
            });
            // this.$router.go(0);
            this.$emit("update");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    pass(id) {
      this.passLoading = true;
      request({
        url: "/api/backend/eduAdmin/dataPass",
        method: "post",
        data: { id },
      }).then((response) => {
        this.passLoading = false;
        this.$message({
          type: "success",
          message: "审核通过",
        });
        // this.$router.go(0);
        this.$emit("update");
      });
    },
    add() {
      if (this.list.length == 0) {
        this.list = this.dataList.data;
      }
      this.list.push({ name: null });
    },
    del(key) {
      if (this.list.length == 0) {
        this.list = this.dataList.data;
      }
      this.list.splice(key, 1);
      this.$emit("edit");
    },
    // uploadExceed() {
    //   this.$message({
    //     message: "上传文件不能超过5个",
    //     type: "warning",
    //   });
    // },
    uploadSuccess(response, file, fileList) {
      let url = {
        url: "",
        name: "",
      };
      url.url = this.qiniu.baseUrl + "/" + response.key;
      url.name = file.name;
      if (this.list.length == 0) {
        this.list = this.dataList.data;
      }
      this.list.push(url);
      // console.log(this.list);
      // setTimeout(() => {
      //   this.dialogVisible = false;
      // }, 2000);
      if (this.progresList.every((item) => item.progress === 100)) {
        setTimeout(() => {
          this.$refs.upload.clearFiles();
        }, 3000);
      }
      this.$emit("edit");
    },
    uploadError() {},
  },
};
</script>
<style lang="scss" scoped>
.progresBox {
  display: flex;
  justify-content: center;
  // flex-direction: ;
  flex-direction: column;
  width: 100%;
}
.title {
  margin-bottom: 20px;
}
.upload-demo {
  margin: 0 10px;
}
.cell {
  border: 1px solid #c4c4c4;
  padding: 15px;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
    margin-top: 10px;
    .name {
      width: 70%;
    }
  }
}
.content {
  width: 60% !important;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom_row {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding: 10px 100px;
    .el-button {
      margin: 0 10px;
    }
  }
  .bottom {
    display: flex;
  }
}
</style>