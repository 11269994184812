import { render, staticRenderFns } from "./coursewareinfo.vue?vue&type=template&id=9c6d72ac&scoped=true&"
import script from "./coursewareinfo.vue?vue&type=script&lang=js&"
export * from "./coursewareinfo.vue?vue&type=script&lang=js&"
import style0 from "./coursewareinfo.vue?vue&type=style&index=0&id=9c6d72ac&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c6d72ac",
  null
  
)

export default component.exports