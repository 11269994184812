<template>
  <div class="app-container">
    <Title :data="data" />
    <div style="display: flex">
      <div style="width: 80%">
        <batchadd
          title="讲义"
          text="讲义"
          :dataList="form.handout"
          @edit="clickUpload('handout')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="教案"
          text="教案"
          :dataList="form.lesson_plan"
          @edit="clickUpload('lesson_plan')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="视频/音频"
          text="视频/音频"
          :dataList="form.video_audio"
          @edit="clickUpload('video_audio')"
          style="margin-bottom: 20px"
        ></batchadd>
      </div>
      <Uptips style="width: 20%"></Uptips>
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >保存并提交</el-button
      >
    </div>
  </div>
</template>

<script>
// import BatchAdd from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
import { logo } from "@/settings";
export default {
  components: {
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      list: [],
      listQuery: {
        type: "",
        class_course_id: "",
      },
      form: null,
      data: {},
      video_audio: [],
      lesson_plan: [],
      handout: [],
    };
  },
  created() {
    this.listQuery.type = this.$route.query.type;
    this.data = JSON.parse(this.$route.query.data);
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    localStorage.setItem("classdata", JSON.stringify(this.data));
    
    this.form = {
      handout: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "handout",
        data: [],
      },
      lesson_plan: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "lesson_plan",
        data: [],
      },
      video_audio: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "video_audio",
        data: [],
      },
    };
    this.getDetail();
    console.log(this.form);
  },
  methods: {
    clickUpload(e) {
      switch (e) {
        case "handout":
          this.form.handout.is_store = 1;
          console.log("handout");
          break;
        case "lesson_plan":
          this.form.lesson_plan.is_store = 1;
          console.log("lesson_plan");
          break;
        case "video_audio":
          this.form.video_audio.is_store = 1;
          break;
      }
    },
    getDetail() {
      request({
        url: "/api/teacherend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data.handout) this.form.handout = response.data.handout;
        if (response.data.lesson_plan)
          this.form.lesson_plan = response.data.lesson_plan;
        if (response.data.video_audio)
          this.form.video_audio = response.data.video_audio;
      });
    },
    submit() {
      if (
        this.form.handout.is_store != 1 &&
        this.form.lesson_plan.is_store != 1 &&
        this.form.video_audio.is_store != 1
      ) {
        this.$alert("请修改或者上传后提交", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            return;
          },
        });
      }
      let submitForm = {};
      if (this.form.handout.is_store) {
        submitForm.handout = this.form.handout;
      }
      if (this.form.lesson_plan.is_store) {
        submitForm.lesson_plan = this.form.lesson_plan;
      }
      if (this.form.video_audio.is_store) {
        submitForm.video_audio = this.form.video_audio;
      }
      if (Object.keys(submitForm).length == 0) {
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/teacherend/eduAdmin/dataStore",
        method: "post",
        data: submitForm,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            message: "提交成功,待审核!",
            type: "success",
          });
          this.getDetail();
          // this.$router.go(0);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    addList() {
      this.list.push({ name: "" });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
